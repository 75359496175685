import * as React from "react";
import { Helmet } from "react-helmet";

// markup
const IndexPage = () => {
  React.useEffect(() => {
    (function () {
      var a = document.createElement("script"),
        h = "head";
      a.async = true;
      a.src =
        (document.location.protocol === "https:" ? "https:" : "http:") +
        "//ucalc.pro/api/widget.js?id=391986&t=" +
        Math.floor(new Date() / 18e5);
      document.getElementsByTagName(h)[0].appendChild(a);
    })();
  });
  return (
    <>
      <Helmet
        bodyAttributes={{
          class: "nk-body body-wider theme-dark mode-onepage",
        }}
      >
        <meta charset="utf-8" />
        <meta name="author" content="ChangeX" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="" />
        <link rel="shortcut icon" href="/assets/images/icon.png" />
        <title>Self Vesting Optional Tool | ChangeX</title>

        <link rel="stylesheet" href="assets/css/vendor.bundle.css?ver=195" />
        <link rel="stylesheet" href="assets/css/style-dark.css" />
        <link rel="stylesheet" href="assets/css/theme.css?ver=195" />
      </Helmet>

      <section class="section bg-theme-dark-alt tc-light" id="platform">
        <div class="container">
          <div class="section-head section-head-s9">
            <h2
              class="title title-semibold animated text-center mb-10"
              data-animate="fadeInUp"
              data-delay=".2"
            >
              Self Vesting Optional Tool
            </h2>
          </div>
          <div class="uCalc_391986"></div>
        </div>
      </section>
    </>
  );
};

export default IndexPage;
